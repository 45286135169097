import React from 'react'
import styled from 'styled-components'
import { faCrown, faStar, faUser } from '@fortawesome/pro-regular-svg-icons'
import { ellipsis } from 'polished'
import IWTypography from './IWTypography'
import IWAvatar from './IWAvatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCrown } from '@fortawesome/pro-solid-svg-icons'

interface ExtraProps {
  name: string
  email?: string
  imageUri?: string
  truncateAtWidth?: number
  imageSize?: number
  isAdmin?: boolean
}

type Props = ExtraProps & React.ComponentPropsWithoutRef<'button'>

const StyledComponentWrapper = styled.li`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  overflow: hidden;
`

const StyledTextContentWrapper = styled.div`
  text-align: left;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
`

const StyledTypography = styled(IWTypography)<{
  truncateAtWidth: number
  truncateAtLine: number
}>`
  ${(props) => ellipsis(props.truncateAtWidth, props.truncateAtLine)};
`

const IWUserListItem = ({
  name,
  email,
  imageUri,
  imageSize = 40,
  truncateAtWidth = 130,
  isAdmin = false,
}: Props) => {
  return (
    <StyledComponentWrapper data-testid="user-tile">
      <IWAvatar
        src={imageUri}
        size={imageSize}
        icon={isAdmin ? faUserCrown : faUser}
        fontHue={
          isAdmin
            ? { color: 'warning', value: 900 }
            : { color: 'grey', value: 400 }
        }
        alt="User"
      />

      <StyledTextContentWrapper>
        <StyledTypography
          size="base"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
          truncateAtWidth={truncateAtWidth}
          truncateAtLine={2}
        >
          {name}
        </StyledTypography>
        <StyledTypography
          size="sm"
          weight="medium"
          fontHue={{ color: 'grey', value: 500 }}
          truncateAtWidth={truncateAtWidth}
          truncateAtLine={1}
        >
          {email}
        </StyledTypography>
      </StyledTextContentWrapper>
    </StyledComponentWrapper>
  )
}

export default IWUserListItem
