import React from 'react'
import CreatePodWizard from 'tools/insightsManager/components/CreatePodWizard'
import { useTranslation } from 'react-i18next'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { FullPageMainContent, OverlayPage } from 'root/components/IWLayout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styled from 'styled-components'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import IWSpacer from 'shared/components/thunderbolt/IWSpacer'

const StyledCaretIcon = styled(FontAwesomeIcon)`
  align-self: center;
  font-size: 1rem;
  color: ${(props) => props.theme.palette.grey[400]};
`

/**
 * Base Page to create different pods, different options for
 * different pod types will happen here
 */
const CreatePodPage = () => {
  const { t } = useTranslation()
  return (
    <OverlayPage>
      <IWPageHeader data-testid="page-header" isFullScreen>
        <StyledTitle>{t('insightManager.insightManager')}</StyledTitle>
        <StyledCaretIcon icon={faChevronRight} />
        <StyledTitle>{t('insightManager.createPod.createPod')}</StyledTitle>
        <IWSpacer />
        <IWButton
          as="routerNavLink"
          to="/tools/insights-manager"
          variant="alternative"
          color="primary"
          data-testid="page-header-close-button"
        >
          {t('button.close')}
        </IWButton>
      </IWPageHeader>
      <FullPageMainContent>
        <CreatePodWizard />
      </FullPageMainContent>
    </OverlayPage>
  )
}

export default CreatePodPage
