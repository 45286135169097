import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import IWButton from 'shared/components/thunderbolt/IWButton'
import { useTranslation } from 'react-i18next'
import IWModal, {
  IWModalContent,
  IWModalFooter,
  IWModalHeader,
} from 'shared/components/thunderbolt/IWModal'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faCube } from '@fortawesome/pro-regular-svg-icons'
import IWDropdown, {
  DropdownValueProps,
} from 'shared/components/thunderbolt/IWDropdown'
import IWUserListItem from 'shared/components/thunderbolt/IWUserListItem'
import UserContext from 'shared/contexts/UserContext'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import { sharePodWithOrg, sharePodWithUsers } from 'shared/podServiceClient'
import { useToast } from 'shared/components/thunderbolt/IWToastContext'
import useGetOrgUsers, { useGetAllOrgs } from 'shared/hooks/useGetOrgUsers'
import { Pod, SharingRole, UserSharingRole } from '../types'
import userContext from 'shared/contexts/UserContext'
import { isPermissionAvailable } from 'shared/helpers'
import { string } from 'mathjs'

export type Props = {
  /** pod being shared */
  pod: Pod
  /** Flag to determine visibility */
  isOpen: boolean
  /** Function to call when the close is pressed */
  handleClose?: () => void
}

interface DropdownOption<T> {
  label: string
  value: T
}

type RoleDropdownOption = DropdownOption<number>

const StyledIWModalHeader = styled(IWModalHeader)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledActions = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
`

const StyledFlexDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`

const StyledIWModalContent = styled(IWModalContent)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledMemberList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const StyledIWMember = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const StyledFixedWidthWrapper = styled.div`
  width: 16rem;
`

const ShareWithModal = ({ pod, isOpen, handleClose }: Props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const toast = useToast()
  const { availableToolPolicies } = useContext(userContext)
  const { organization: org } = useContext(UserContext)
  const [stagedChanges, setStagedChanges] = useState<{
    userIdsToAdd: string[]
    addToOrg: Array<{
      orgId: string
      role: SharingRole
    }>
  }>({
    userIdsToAdd: [],
    addToOrg: [],
  })

  const roleOptions: RoleDropdownOption[] = [
    { value: 1, label: t('insightManager.pods.sharing.roles.viewOnly') },
    { value: 2, label: t('insightManager.pods.sharing.roles.editor') },
  ]

  const [selectedSharingRole, setSelectedSharingRole] = useState<SharingRole>(
    roleOptions[0].value as SharingRole,
  )

  const [localCurrentlySharedWithUsers, setLocalCurrentlySharedWithUsers] =
    useState<UserSharingRole[]>(pod.sharedWithUsers || [])

  const [
    localIsCurrentlySharedWithOrgRole,
    setLocalIsCurrentlySharedWithOrgRole,
  ] = useState<Array<{ orgId: string; role: SharingRole }> | undefined>(
    pod.sharedWithOrgRole,
  )

  const [stagedChangesValue, setStagedChangesValue] = useState<
    DropdownValueProps[]
  >([])
  const { data: availableUsers, isLoading: initializing } = useGetOrgUsers()

  const isAdmin = isPermissionAvailable(availableToolPolicies, 'system:admin')

  const { data: allOrgs, isLoading: allOrgsInitializing } =
    useGetAllOrgs(isAdmin)

  const handleShareWithOrg = async (
    userPodId: string,
    value: boolean,
    role: Array<{
      orgId: string
      role: SharingRole
    }>,
  ) => {
    try {
      setLoading(true)
      if (value) {
        await sharePodWithOrg(userPodId, true, role)
      } else {
        await sharePodWithOrg(userPodId, false, role)
      }
      toast.secondary(t('insightManager.pods.sharing.successfullyShared'))
      return true
    } catch (e) {
      toast.alert(t('insightManager.pods.sharing.shareError'))
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleShareWithUsers = async (
    userPodId: string,
    usersToAdd: UserSharingRole[] = [],
    userIdsToRemove: string[] = [],
  ) => {
    setLoading(true)
    try {
      await sharePodWithUsers(userPodId, usersToAdd, userIdsToRemove)
      toast.secondary(t('insightManager.pods.sharing.successfullyShared'))
      return true
    } catch (e) {
      toast.alert(t('insightManager.pods.sharing.shareError'))
      return false
    } finally {
      setLoading(false)
    }
  }

  const handleSetStagedChanges = (values: DropdownValueProps[]) => {
    setStagedChangesValue(values)
    const ids = values.map((v) => v.value)
    const newStagedChanges = {
      ...stagedChanges,
    }
    const userSharingValues: string[] = []
    const orgSharingValues: string[] = []

    // Check if the org id is in the dropdown values
    if (ids.includes(org)) orgSharingValues.push(org)

    ids
      .filter((i) => i !== org)
      .forEach((value) => {
        if (value.startsWith('org_')) {
          orgSharingValues.push(value)
        } else {
          userSharingValues.push(value)
        }
      })

    newStagedChanges.addToOrg = orgSharingValues.map((orgVal) => ({
      orgId: orgVal,
      role: selectedSharingRole,
    }))
    newStagedChanges.userIdsToAdd = userSharingValues
    setStagedChanges(newStagedChanges)
  }

  const handleInvite = async () => {
    // Check if the org id is in the dropdown values
    if (stagedChanges.userIdsToAdd.length > 0) {
      await handleShareWithUsers(
        pod.userPodId,
        stagedChanges.userIdsToAdd.map((uid) => ({
          userId: uid,
          sharingRole: selectedSharingRole,
        })),
        [],
      )
      setLocalCurrentlySharedWithUsers([
        ...localCurrentlySharedWithUsers,
        ...stagedChanges.userIdsToAdd.map((u) => ({
          userId: u,
          sharingRole: selectedSharingRole,
        })),
      ])
    }
    if (stagedChanges.addToOrg && stagedChanges.addToOrg.length > 0) {
      await handleShareWithOrg(pod.userPodId, true, stagedChanges.addToOrg)
      setLocalIsCurrentlySharedWithOrgRole((prev) => [
        ...(prev ?? []),
        ...stagedChanges.addToOrg,
      ])
    }

    // Once invited, we reset the staged changes
    setStagedChanges({ userIdsToAdd: [], addToOrg: [] })
    setStagedChangesValue([])
  }

  const handleChangeUserRole = async (
    userId: string,
    role: RoleDropdownOption,
  ) => {
    const roleToAssing = role.value as SharingRole

    await handleShareWithUsers(
      pod.userPodId,
      [
        {
          userId,
          sharingRole: roleToAssing,
        },
      ],
      [],
    )
  }

  const handleChangeOrgRole = async (
    orgId: string,
    role: RoleDropdownOption,
  ) => {
    const roleToAssing = role.value as SharingRole

    await handleShareWithOrg(pod.userPodId, true, [
      {
        orgId,
        role: roleToAssing,
      },
    ])
  }

  const handleRemoveOrg = async (
    orgList: Array<{ orgId: string; role: number }>,
  ) => {
    const success = await handleShareWithOrg(pod.userPodId, false, orgList)
    if (success) {
      setLocalIsCurrentlySharedWithOrgRole((prev) => {
        return prev?.filter(({ orgId }) => {
          return !orgList.find(
            ({ orgId: deletedOrgId }) => deletedOrgId === orgId,
          )
        })
      })
    }
  }

  const handleRemoveUser = async (userId: string) => {
    const success = await handleShareWithUsers(pod.userPodId, [], [userId])

    if (success) {
      setLocalCurrentlySharedWithUsers(
        localCurrentlySharedWithUsers.filter(
          (u: UserSharingRole) => u.userId !== userId,
        ),
      )
    }
  }

  const renderMembers = () => {
    // We always show the owner of the pod and they can't be removed
    const matchedPodOwner = availableUsers?.obj[pod.userId]
    const podOwner = (
      <StyledIWMember data-testid="sharing-user" key={pod.userId}>
        <StyledFixedWidthWrapper>
          <IWUserListItem
            truncateAtWidth={300}
            name={
              matchedPodOwner
                ? matchedPodOwner.name
                : t('insightManager.pods.sharing.unknownUser')
            }
            email={
              matchedPodOwner
                ? matchedPodOwner.email
                : t('insightManager.pods.sharing.unknownUser')
            }
          />
        </StyledFixedWidthWrapper>
        <IWDropdown
          disabled
          flexGrow
          value={{
            value: 'OWNER',
            label: t('insightManager.pods.sharing.roles.owner'),
          }}
          options={[
            {
              value: 'OWNER',
              label: t('insightManager.pods.sharing.roles.owner'),
            },
          ]}
        />
      </StyledIWMember>
    )
    const selectedOrg =
      localIsCurrentlySharedWithOrgRole &&
      localIsCurrentlySharedWithOrgRole.length > 0
        ? localIsCurrentlySharedWithOrgRole.map(({ orgId, role }) => {
            const matchedOrg = org === orgId
            // allOrgs?.find((o) => o === orgId)
            return (
              <StyledIWMember data-testid="sharing-org" key={orgId}>
                <StyledFixedWidthWrapper>
                  <IWUserListItem
                    truncateAtWidth={300}
                    name={`${
                      matchedOrg
                        ? t('insightManager.pods.sharing.myOrganization')
                        : orgId
                    }`}
                    email={orgId}
                  />
                </StyledFixedWidthWrapper>
                <IWDropdown
                  disabled={loading}
                  flexGrow
                  onChange={(value) => handleChangeOrgRole(orgId, value)}
                  defaultValue={roleOptions.find((r) => r.value === role)}
                  options={roleOptions}
                />
                <IWButton
                  variant="outline"
                  color="grey"
                  data-testid="sharing-remove-org-button"
                  disabled={loading}
                  onClick={() =>
                    handleRemoveOrg([
                      {
                        orgId,
                        role,
                      },
                    ])
                  }
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </IWButton>
              </StyledIWMember>
            )
          })
        : ''

    const selectedUsers = localCurrentlySharedWithUsers
      ? localCurrentlySharedWithUsers.map((user: UserSharingRole) => {
          const matchedUser = availableUsers?.obj[user.userId]
          return (
            matchedUser && (
              <StyledIWMember data-testid="sharing-user" key={matchedUser.id}>
                <StyledFixedWidthWrapper>
                  <IWUserListItem
                    truncateAtWidth={300}
                    name={matchedUser.name}
                    email={matchedUser.email}
                  />
                </StyledFixedWidthWrapper>

                <IWDropdown
                  flexGrow
                  onChange={(val) => handleChangeUserRole(user.userId, val)}
                  defaultValue={roleOptions.find(
                    (r) => r.value === user.sharingRole,
                  )}
                  options={roleOptions}
                />
                <IWButton
                  disabled={loading}
                  variant="outline"
                  color="grey"
                  data-testid="sharing-remove-user-button"
                  onClick={() => handleRemoveUser(matchedUser.id)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </IWButton>
              </StyledIWMember>
            )
          )
        })
      : []
    return [podOwner, ...(selectedOrg || []), ...selectedUsers]
  }
  return (
    <IWModal open={isOpen} onClose={handleClose}>
      <StyledIWModalHeader>
        <IWTypography
          size="lg"
          weight="medium"
          fontHue={{ color: 'grey', value: 900 }}
        >
          {t('insightManager.pods.sharing.shareWith')}
        </IWTypography>
        {initializing && allOrgsInitializing ? (
          <IWLoading />
        ) : (
          <>
            <StyledFlexDiv>
              <StyledFixedWidthWrapper>
                <IWDropdown
                  isMulti
                  menuWidth="29rem"
                  disabled={loading}
                  onChange={handleSetStagedChanges}
                  value={stagedChangesValue}
                  options={[
                    ...[
                      {
                        value: org,
                        label: `${t(
                          'insightManager.pods.sharing.myOrganization',
                        )}`,
                      },
                      ...(allOrgs?.map((o) => ({
                        value: o,
                        label: o,
                      })) || []),
                    ].filter(
                      (u) =>
                        !pod.sharedWithOrgRole?.find(
                          (o) => o.orgId === u.value,
                        ) ||
                        !localIsCurrentlySharedWithOrgRole?.find(
                          (o) => o.orgId === u.value,
                        ),
                    ),
                    ...(availableUsers?.list
                      .filter(
                        (u) =>
                          u.id !== pod.userId &&
                          !localCurrentlySharedWithUsers.find(
                            (localUser: UserSharingRole) =>
                              u.id === localUser.userId,
                          ),
                      )
                      .map((u) => ({
                        value: u.id,
                        label: u.name,
                      })) || []),
                  ]}
                  // org on top
                  placeholder={t('insightManager.pods.sharing.searchMembers')}
                />
              </StyledFixedWidthWrapper>
              <IWDropdown
                flexGrow
                disabled={loading}
                onChange={(val) => setSelectedSharingRole(val.value)}
                defaultValue={roleOptions[0]}
                options={roleOptions}
              />
              <IWButton
                onClick={handleInvite}
                disabled={
                  loading ||
                  !(
                    stagedChanges.addToOrg ||
                    stagedChanges.userIdsToAdd.length > 0
                  )
                }
                data-testid="invite-button"
              >
                {t('insightManager.pods.sharing.invite')}
              </IWButton>
            </StyledFlexDiv>
            <StyledFlexDiv>
              <IWTypography fontHue={{ value: 700, color: 'grey' }}>
                <FontAwesomeIcon icon={faCube} />
              </IWTypography>
              <IWTypography
                data-testid="pod-name"
                fontHue={{ value: 700, color: 'grey' }}
              >
                {`"`}
                {pod?.userPodName}
                {`"`} {t('insightManager.pods.sharing.selected')}
              </IWTypography>
            </StyledFlexDiv>
          </>
        )}
      </StyledIWModalHeader>
      <StyledIWModalContent data-testid="share-modal">
        <StyledMemberList data-testid="member-list">
          {renderMembers()}
        </StyledMemberList>
      </StyledIWModalContent>
      <IWModalFooter>
        <StyledActions>
          <IWButton
            data-testid="share-modal-close"
            variant="main"
            color="primary"
            disabled={loading}
            onClick={handleClose}
          >
            {t(`button.close`)}
          </IWButton>
        </StyledActions>
      </IWModalFooter>
    </IWModal>
  )
}

export default ShareWithModal
