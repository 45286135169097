import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router'
import UserContext from 'shared/contexts/UserContext'
import { isPermissionAvailable } from 'shared/helpers'
import InsightManager from './pages/InsightManager'
import CreatePodPage from './pages/CreatePodPage'
import PodDetailsPage from './pages/PodDetailsPage'

const InsightsManagerWrapper = () => {
  const { availableTools } = useContext(UserContext)
  const isAuthorised = availableTools.includes('insightsManager')

  return isAuthorised ? <Outlet /> : <Navigate to="/not-authorised" replace />
}

const CreatePodWrapper = () => {
  const { availableToolPolicies } = useContext(UserContext)

  return isPermissionAvailable(
    availableToolPolicies,
    'insightsManager:editor',
  ) ? (
    <CreatePodPage />
  ) : (
    <Navigate to="/not-authorised" replace />
  )
}

// eslint-disable-next-line import/prefer-default-export
export const routes = [
  {
    title: 'Insights Manager',
    key: 'insightsManager',
    path: '/insights-manager',
    main: <InsightsManagerWrapper />,
    childRoutes: [
      {
        title: 'Insights Manager',
        key: 'main',
        main: <InsightManager />,
        entryPoint: true,
      },
      {
        title: 'Pod Details',
        key: 'pod-details',
        path: '/pod-details/:podId',
        main: <PodDetailsPage />,
      },
      {
        title: 'Create Pod',
        key: 'create-pod',
        path: '/create-pod',
        main: <CreatePodWrapper />,
      },
    ],
  },
]
