import { DateTime } from 'luxon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type {
  DropdownValueProps,
  GenericDropdownValue,
} from 'shared/components/thunderbolt/IWDropdown'
import type { MainOptions } from 'shared/components/thunderbolt/IWRelativeDatePicker'
import useIWRelativeDatePicker, {
  defaultHasErrorsCalculation,
  defaultIndividualErrorsCalculation,
  type DefaultState,
} from 'shared/hooks/useIWRelativeDatePicker'
import type { LsSchedulingType, LsSubmissionStatus } from '../types'

export type AvailableSubmissionHistoryFilters =
  | 'market'
  | 'brand'
  | 'location'
  | 'submittedBy'
  | 'submittedDate'
  | 'startDate'
  | 'endDate'
  | 'date'
  | 'wasSuccessful'
  | 'submissionStatus'
  | 'scheduleType'

const submittedDatePickerDisabledMainOptions: MainOptions[] = [
  'after',
  'before',
  'previous',
  'next',
  'between',
]

const dateDatePickerDisabledMainOptions: MainOptions[] = [
  'after',
  'before',
  'previous',
  'next',
  'on',
]

export const useSubmissionHistoryFilters = () => {
  const { t } = useTranslation()
  const [marketOptions, setMarketOptions] = useState<DropdownValueProps[]>([])
  const [brandOptions, setBrandOptions] = useState<DropdownValueProps[]>([])
  const [submissionStatusOptions, setSubmissionStatusOptions] = useState<
    GenericDropdownValue<LsSubmissionStatus & 'all'>[]
  >([])
  const [scheduleTypeOptions, setScheduleTypeOptions] = useState<
    GenericDropdownValue<LsSchedulingType & 'all'>[]
  >([])
  const [locationOptions, setLocationOptions] = useState<DropdownValueProps[]>(
    [],
  )
  const [submittedByOptions, setSubmittedByOptions] = useState<
    DropdownValueProps[]
  >([])
  const [isSubmittedDatePickerOpen, setSubmittedDatePickerIsOpen] =
    useState<boolean>(false)
  const [isDateDatePickerOpen, setDateDatePickerIsOpen] =
    useState<boolean>(false)

  const [selectedMarket, setSelectedMarket] = useState<DropdownValueProps>()
  const [selectedBrand, setSelectedBrand] = useState<DropdownValueProps>()
  const [selectedLocation, setSelectedLocation] = useState<DropdownValueProps>()
  const [selectedSubmissionStatus, setSelectedSubmissionStatus] =
    useState<GenericDropdownValue<LsSubmissionStatus & 'all'>>()
  const [selectedScheduleType, setSelectedScheduleType] =
    useState<GenericDropdownValue<LsSchedulingType & 'all'>>()
  const [selectedSubmittedBy, setSelectedSubmittedBy] =
    useState<DropdownValueProps>()

  const [submittedDatePickerDefaults, setSubmittedDatePickerDefaults] =
    useState<DefaultState>({
      selectedMainOption: { value: 'on' },
      selectedTimeLengthOption: { value: 'days' },
      selectedRelativeOption: { value: 'today' },
      selectedSpecificDatePickerDate: DateTime.local(),
      selectedDatePickerMin: DateTime.local(),
      selectedDatePickerMax: DateTime.local(),
      timeLengthValue: 30,
      disabledMainOptions: submittedDatePickerDisabledMainOptions,
    })
  const [dateDatePickerDefaults, setDateDatePickerDefaults] =
    useState<DefaultState>({
      selectedMainOption: { value: 'between' },
      selectedTimeLengthOption: { value: 'days' },
      selectedRelativeOption: { value: 'today' },
      selectedSpecificDatePickerDate: DateTime.local(),
      selectedDatePickerMin: DateTime.local().plus({ day: 1 }),
      selectedDatePickerMax: DateTime.local().plus({ day: 8 }),
      timeLengthValue: 30,
      disabledMainOptions: dateDatePickerDisabledMainOptions,
    })

  const submittedDatePickerState = useIWRelativeDatePicker({
    ...submittedDatePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const dateDatePickerState = useIWRelativeDatePicker({
    ...dateDatePickerDefaults,
    hasErrorsCalculation: defaultHasErrorsCalculation,
    individualErrorCalculation: defaultIndividualErrorsCalculation,
  })

  const handleSubmittedDatePickerCancel = () => {
    submittedDatePickerState.modifyFullState(submittedDatePickerDefaults)
    setSubmittedDatePickerIsOpen(false)
  }

  const handleDateDatePickerCancel = () => {
    dateDatePickerState.modifyFullState(dateDatePickerDefaults)
    setDateDatePickerIsOpen(false)
  }

  const handleSubmittedDatePickerConfirm = () => {
    setSubmittedDatePickerDefaults({
      selectedMainOption: submittedDatePickerState.selectedMainOption,
      selectedTimeLengthOption:
        submittedDatePickerState.selectedTimeLengthOption,
      selectedRelativeOption: submittedDatePickerState.selectedRelativeOption,
      selectedSpecificDatePickerDate:
        submittedDatePickerState.selectedSpecificDatePickerDate,
      selectedDatePickerMin: submittedDatePickerState.selectedDatePickerMin,
      selectedDatePickerMax: submittedDatePickerState.selectedDatePickerMax,
      timeLengthValue: submittedDatePickerState.timeLengthValue,
      disabledMainOptions: submittedDatePickerDisabledMainOptions,
    })
    setSubmittedDatePickerIsOpen(false)
  }

  const handleDateDatePickerConfirm = () => {
    setDateDatePickerDefaults({
      selectedMainOption: dateDatePickerState.selectedMainOption,
      selectedTimeLengthOption: dateDatePickerState.selectedTimeLengthOption,
      selectedRelativeOption: dateDatePickerState.selectedRelativeOption,
      selectedSpecificDatePickerDate:
        dateDatePickerState.selectedSpecificDatePickerDate,
      selectedDatePickerMin: dateDatePickerState.selectedDatePickerMin,
      selectedDatePickerMax: dateDatePickerState.selectedDatePickerMax,
      timeLengthValue: dateDatePickerState.timeLengthValue,
      disabledMainOptions: dateDatePickerDisabledMainOptions,
    })
    setDateDatePickerIsOpen(false)
  }

  const populateFilters = (filters: Record<string, string[]>) => {
    const all = { label: 'All', value: 'all' }
    const markets = [
      all,
      ...(filters.market?.map((v) => ({ label: v, value: v })) || []),
    ]
    const brands = [
      all,
      ...(filters.brand?.map((v) => ({ label: v, value: v })) || []),
    ]
    const locations = [
      all,
      ...(filters.location?.map((v) => ({ label: v, value: v })) || []),
    ]
    const submittedBys = [
      all,
      ...(filters.created_by?.map((v) => ({ label: v, value: v })) || []),
    ]
    const submissionStatuses = [
      all,
      {
        label: t('loadScheduling.submissionHistoryOptions.labels.processing'),
        value: 'processingSubmission',
      },
      {
        label: t('loadScheduling.submissionHistoryOptions.labels.completed'),
        value: 'completedSubmission',
      },
      {
        label: t('loadScheduling.submissionHistoryOptions.labels.failed'),
        value: 'failedSubmission',
      },
    ] as GenericDropdownValue<LsSubmissionStatus & 'all'>[]

    const scheduleTypes = [
      all,
      {
        label: t('loadScheduling.demandType'),
        value: 'DEMAND_SCHEDULING',
      },
      {
        label: t('loadScheduling.physicalType'),
        value: 'PHYSICAL_SCHEDULING',
      },
    ] as GenericDropdownValue<LsSubmissionStatus & 'all'>[]

    setMarketOptions(markets)
    setBrandOptions(brands)
    setLocationOptions(locations)
    setSubmittedByOptions(submittedBys)
    setSubmissionStatusOptions(submissionStatuses)
    setScheduleTypeOptions(scheduleTypes)
  }

  const handleOnSelect = (
    key: AvailableSubmissionHistoryFilters,
    selection: GenericDropdownValue<unknown> | DropdownValueProps,
  ) => {
    if (key === 'market') {
      setSelectedMarket(selection as DropdownValueProps)
    }

    if (key === 'brand') {
      setSelectedBrand(selection as DropdownValueProps)
    }

    if (key === 'location') {
      setSelectedLocation(selection as DropdownValueProps)
    }

    if (key === 'submittedBy') {
      setSelectedSubmittedBy(selection as DropdownValueProps)
    }

    if (key === 'submissionStatus') {
      setSelectedSubmissionStatus(
        selection as GenericDropdownValue<LsSubmissionStatus & 'all'>,
      )
    }

    if (key === 'scheduleType') {
      setSelectedScheduleType(
        selection as GenericDropdownValue<LsSchedulingType & 'all'>,
      )
    }
  }

  const getFiltersValues = (): Record<string, string> => {
    const searchQuery: { [x: string]: string } = {}

    if (selectedMarket?.value && selectedMarket?.value !== 'all') {
      searchQuery.market = selectedMarket.value
    }

    if (selectedBrand?.value && selectedBrand?.value !== 'all') {
      searchQuery.brand = selectedBrand.value
    }

    if (selectedLocation?.value && selectedLocation?.value !== 'all') {
      searchQuery.location = selectedLocation.value
    }

    if (selectedSubmittedBy?.value && selectedSubmittedBy?.value !== 'all') {
      searchQuery.submittedBy = selectedSubmittedBy.value
    }

    if (
      selectedSubmissionStatus?.value &&
      selectedSubmissionStatus?.value !== 'all'
    ) {
      searchQuery.submissionStatus = selectedSubmissionStatus.value
    }

    if (selectedScheduleType?.value && selectedScheduleType?.value !== 'all') {
      searchQuery.type = selectedScheduleType.value
    }

    if (submittedDatePickerState.selectedSpecificDatePickerDate !== undefined) {
      searchQuery.submittedDate =
        submittedDatePickerState.selectedSpecificDatePickerDate
          ?.setZone('UTC')
          .toISODate()
    }

    if (dateDatePickerState.selectedDatePickerMin !== undefined) {
      searchQuery.startDate =
        dateDatePickerState.selectedDatePickerMin?.toISODate()
    }

    if (dateDatePickerState.selectedDatePickerMax !== undefined) {
      searchQuery.endDate =
        dateDatePickerState.selectedDatePickerMax?.toISODate()
    }

    return searchQuery
  }

  return {
    populateFilters,
    handleOnSelect,
    getFiltersValues,
    marketOptions,
    brandOptions,
    locationOptions,
    submittedByOptions,
    submissionStatusOptions,
    scheduleTypeOptions,
    setSubmittedDatePickerIsOpen,
    setDateDatePickerIsOpen,
    setSubmittedDatePickerDefaults,
    setDateDatePickerDefaults,
    selectedMarket,
    selectedBrand,
    selectedLocation,
    selectedSubmittedBy,
    selectedSubmissionStatus,
    selectedScheduleType,
    isSubmittedDatePickerOpen,
    isDateDatePickerOpen,
    submittedDatePickerDefaults,
    dateDatePickerDefaults,
    submittedDatePickerState,
    dateDatePickerState,
    handleSubmittedDatePickerCancel,
    handleDateDatePickerCancel,
    handleSubmittedDatePickerConfirm,
    handleDateDatePickerConfirm,
  }
}
