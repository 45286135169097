import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import IWPageHeader, {
  StyledTitle,
} from 'shared/components/thunderbolt/IWPageHeader'
import { ReactComponent as NoPodsImg } from 'images/no-pods.svg'
import IWTab from 'shared/components/thunderbolt/IWTab'
import {
  IWGenericCard,
  IWGenericCardBody,
  IWGenericCardFooter,
} from 'shared/components/thunderbolt/IWGenericCard'
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import IWTypography from 'shared/components/thunderbolt/IWTypography'
import { AvailableColors } from '../../@types/styled'
import IWTabs from 'shared/components/thunderbolt/IWTabs'
import { Link } from 'react-router-dom'
import UserContext from 'shared/contexts/UserContext'
import { useQuery } from 'react-query'
import { getDashboardList } from 'shared/podServiceClient'
import IWLoading from 'shared/components/thunderbolt/IWLoading'
import IWNoResultsMessage from 'shared/components/thunderbolt/IWNoResultsMessage'
import IWButton from 'shared/components/thunderbolt/IWButton'

const CardFooter = styled(IWGenericCardFooter)`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.palette.grey[50]};
`

const EmptyDiv = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  align-items: center;

  & > * {
    width: 400px;
  }
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 815px;
  border: 0px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 0.25rem;
`

const StyledGenericCardBody = styled(IWGenericCardBody)`
  margin: 0px;
`

const FullPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 0 auto;
  padding-bottom: 2rem;
  width: 100%;
`

const StyledMainReleaseNoteContainer = styled.div`
  display: flex;
  height: 900px;
  border: 1px solid #e6e7eb;
`
const StyledLeftSection = styled.div`
  flex-basis: 45%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`

const StyledRightSection = styled.div`
  flex-basis: 55%;
  background: ${(props) => props.theme.palette.grey[300]};
  background-image: url(https://www.innowatts.com/wp-content/uploads/2023/03/banner-img-desk.jpg);
  background-blend-mode: luminosity;
  background-position: center;
`
/**
 * Props required to showcase summary of pods on the overview page
 */
export interface PodProps {
  header: string
  icon: IconDefinition
  iconColor?: AvailableColors
  totalAvailable?: number
}

/**
 * Props required to showcase summary of tools on the overview page
 */
export interface ToolProps {
  header: string
  icon: IconDefinition
  iconColor?: AvailableColors
}

/**
 * Values required by the Overview page.
 */
export interface Props {
  /** Pods prop points to various Insights with their respective pods in it. */
  pods?: Array<PodProps>
  /** Tools prop points to various tools that the application has to create different insights */
  tools?: Array<ToolProps>
}

/**
 * Application Overview page which takes in props of pods and tools and displays them onto the screen.
 */
const OverviewPage = () => {
  const user = useContext(UserContext)
  const { t } = useTranslation()
  const { availableDashboardType } = user

  const selectedDashboardCategory = ['Overview']
  const currentPage = 0
  const itemsPerPage = 1
  const showSummary = availableDashboardType.includes(
    selectedDashboardCategory[0],
  )

  const [activeTab, setActiveTab] = useState<number>(1)

  const EmptyMessage = () => {
    return (
      <EmptyDiv data-testid="empty-message">
        <NoPodsImg />
        <IWTypography weight="bold">
          {t('dashboardManager.noOverviewDashboards')}
        </IWTypography>
        <IWTypography size="sm" fontHue={{ color: 'grey', value: 500 }}>
          {t('dashboardManager.noOverviewDashboardsDesc')}
        </IWTypography>
      </EmptyDiv>
    )
  }

  const { data: userDashboards, isLoading: isLoadingUserDasboards } = useQuery(
    [
      'dashboards',
      'overview',
      'pageNumber',
      currentPage,
      'pageSize',
      itemsPerPage,
    ],
    () => {
      return getDashboardList({
        dashboardCategory: selectedDashboardCategory,
        currentPage,
        itemsPerPage,
      })
    },
  )

  return (
    <>
      <IWPageHeader data-testid="page-header">
        <StyledTitle>{t('application.titles.overview')}</StyledTitle>
      </IWPageHeader>

      <FullPageContent>
        <IWTabs activeTabIndex={activeTab} onTabChange={setActiveTab}>
          <IWTab
            label={t('application.labels.summary')}
            disabled={
              !availableDashboardType.includes(selectedDashboardCategory[0])
            }
          />
          <IWTab label={t('application.labels.latestNews')} />
        </IWTabs>
        {activeTab === 0 && showSummary && (
          <IWGenericCard>
            <StyledGenericCardBody>
              {isLoadingUserDasboards && <IWLoading />}
              {!isLoadingUserDasboards && userDashboards?.total === 0 && (
                <EmptyMessage />
              )}
              {!isLoadingUserDasboards &&
                userDashboards &&
                userDashboards.total > 0 && (
                  <>
                    {userDashboards.rows.length > 0 ? (
                      <>
                        {userDashboards.rows.map((dashboardUrl, i) => {
                          return (
                            <StyledIframe
                              title={`dashboard-${i}`}
                              id="pivot"
                              src={dashboardUrl}
                            />
                          )
                        })}
                      </>
                    ) : (
                      <IWNoResultsMessage />
                    )}
                  </>
                )}
            </StyledGenericCardBody>
            <CardFooter>
              <Link to="/tools/dashboards-manager">
                <IWTypography size="base">
                  {t('application.innoPods.allDashboards')}
                </IWTypography>
              </Link>
            </CardFooter>
          </IWGenericCard>
        )}
        {activeTab === 1 && (
          <>
            <StyledMainReleaseNoteContainer>
              <StyledLeftSection>
                <IWTypography weight="regular" size="xxxl">
                  {t('application.labels.innowattsProductRelease')}
                </IWTypography>
                <IWButton
                  as="a"
                  href="https://www.innowatts.com/iwplatrn/"
                  target="_blank"
                >
                  {t('button.readMore')}
                </IWButton>
              </StyledLeftSection>
              <StyledRightSection />
            </StyledMainReleaseNoteContainer>
          </>
        )}
      </FullPageContent>
    </>
  )
}

export default OverviewPage
