import React, { ReactNode } from 'react'
import styled from 'styled-components'
import IWTypography from 'shared/components/thunderbolt/IWTypography'

export const StyledNavigation = styled.nav<{ collapsed?: boolean }>`
  display: flex;
  align-items: ${(props) => (props.collapsed ? 'center' : 'stretch')};
  overflow: auto;
  flex-direction: column;
  padding: 1rem;
  flex-grow: 1;
`

interface VerticalNavSectionProps {
  /**
   * Group of section children, usually IWVerticalNavItems
   */
  children: ReactNode
  /**
   * Optional title for the section
   */
  title?: string
}

const StyledIWTypography = styled(IWTypography)`
  margin-left: 1rem;
  padding: 0.5rem 0;
`

/**
 * This is VerticalNavSection component, it is used as a child component to VerticalNavigation component. it takes in an array of
 * IWVerticalNavItem and/or IWUserTile as its children.
 */
const IWVerticalNavSection = ({ title, children }: VerticalNavSectionProps) => {
  return (
    <section>
      {title && (
        <StyledIWTypography as="div" size="sm" weight="bold">
          {title}
        </StyledIWTypography>
      )}
      {children}
    </section>
  )
}

export default IWVerticalNavSection
